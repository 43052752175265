var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import ItemsList from '@shared/ui/display/ItemsList';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { StepContainer } from '@shared/ui/sidebars/shared';
import { ReactComponent as ClosePortfolioIcon } from '@icons/custom/close-portfolio.svg';
import { ReactComponent as AlertIcon } from '@icons/wolfkit-solid/alert-triangle-solid.svg';
import Icon from '@shared/ui/icons/Icon';
import Button from '@shared/ui/buttons/Button';
import { BodyLarge, BodySemiBold } from '@src/components/styled/Typography';
import { ContainerRow } from '@src/components/styled';
import { useAppSelector } from '@app/store/Hooks';
import { removePortfolio, resetFormValues, } from '@entities/exchange';
import { useDeletePortfolioMutation, useGetCurrentProfilePortfoliosQuery } from '@shared/api/portfolio';
const Title = styled(BodyLarge)(() => ({
    fontSize: 24,
    letterSpacing: '-0.456px',
    lineHeight: '36px',
}));
const PortfolioTitle = styled.span(() => ({
    fontWeight: 600,
}));
const WarningContainer = styled(ContainerRow)(props => ({
    height: 'auto',
    background: '#F8F9FA',
    padding: props.theme.spacing_sizes.xm,
    alignItems: 'center',
    gap: props.theme.spacing_sizes.m,
    boxSizing: 'border-box',
    borderRadius: 6,
}));
const WarningTextContainer = styled.div();
const WarningTitle = styled(BodySemiBold)(props => ({
    fontWeight: 600,
    letterSpacing: '-0.084px',
    marginBottom: props.theme.spacing_sizes.xs * 0.25,
}));
const ClosePortfolioSidebar = ({ handleClose, }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useAppSelector(state => state.user);
    const { selectedPortfolioId } = useAppSelector(state => state.exchange);
    const { currentData: portfoliosList = [] } = useGetCurrentProfilePortfoliosQuery({
        profileId: user === null || user === void 0 ? void 0 : user.activeProfileId,
    }, { skip: typeof (user === null || user === void 0 ? void 0 : user.activeProfileId) === 'undefined' });
    const [deletePortfolioReq, { isLoading: isClosing, }] = useDeletePortfolioMutation();
    const handlePortfolioClose = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedPortfolioId) {
            const res = yield deletePortfolioReq({ id: selectedPortfolioId });
            if (!(res === null || res === void 0 ? void 0 : res.error)) {
                dispatch(removePortfolio());
                dispatch(resetFormValues());
            }
        }
    });
    const selectedPortfolio = useMemo(() => portfoliosList.find(({ id }) => id === selectedPortfolioId), [selectedPortfolioId, portfoliosList]);
    return (_jsx(SidebarInner, { padding: `${theme.spacing_sizes.xs * 6.25}px ${theme.spacing_sizes.s * 5}px`, cancelBtnProps: { onClick: handleClose }, displayCancelBtn: true, children: _jsx(StepContainer, { title: (_jsx(Title, { children: Trans({
                    i18nKey: 'overview.close_portfolio.title',
                    values: {
                        portfolioName: (selectedPortfolio === null || selectedPortfolio === void 0 ? void 0 : selectedPortfolio.portfolioName) || '',
                    },
                    components: [_jsx(PortfolioTitle, {}, 'portfolio-name')],
                }) })), content: (_jsxs(_Fragment, { children: [_jsx(Icon, { IconComponent: ClosePortfolioIcon, size: 200, keepOriginalColor: true }), _jsxs(WarningContainer, { children: [_jsx(Icon, { IconComponent: AlertIcon, size: 24, color: theme.palette.warning.main }), _jsxs(WarningTextContainer, { children: [_jsx(WarningTitle, { children: t('overview.close_portfolio.warning.title') }), _jsx(BodySemiBold, { children: t('overview.close_portfolio.warning.text') })] })] }), _jsx(ItemsList, { listStyle: 'check-icon-solid', list: t('overview.close_portfolio.description_items', { returnObjects: true }), itemPadding: '0px', listItemStyles: {
                            '& .MuiListItemIcon-root': {
                                marginTop: theme.spacing_sizes.xs * 0.5,
                                marginRight: theme.spacing_sizes.xs * 1.25,
                                alignSelf: 'start',
                            },
                        }, listItemTextStyles: {
                            '& .MuiTypography-root': {
                                lineHeight: '24px',
                            },
                        } })] })), containerPaddingTop: theme.spacing_sizes.xs * 2, contentJustify: 'space-around', contentFlex: 1, buttons: (_jsxs(_Fragment, { children: [_jsx(Button
                    // onClick={handlePortfolioClose}
                    , { 
                        // onClick={handlePortfolioClose}
                        onClick: handleClose, size: 'large', fullWidth: true, isLoading: isClosing, disabled: isClosing, children: t('overview.close_portfolio.continue_btn') }), _jsx(Button, { variant: 'tinted', size: 'large', onClick: handleClose, fullWidth: true, disabled: isClosing, children: t('overview.close_portfolio.cancel_btn') })] })), buttonsGap: theme.spacing_sizes.m }) }));
};
export default ClosePortfolioSidebar;
